import API from "./apiConfig";

export default {

    // getEvents(){
    //     return API.get('/getEvents');
    // },
    // getEventData(id){
    //     return API.get(`/getEventData/${id}`);
    // },
    // updateEvent(data){
    //     return API.post('/updateEvent', {data});
    // },
    // postEvents(formData){
    //     return API.post('/postEvents', {formData});
    // },
    // getEventImg(id){
    //     return API.get(`/getEventImg/${id}`);
    // },
    // getBlog(){
    //     return API.get('/getBlog');
    // },


    getDepartments() {
        return API.get('/getDepartments');
    },

    getDoctors(dept) {
        return API.get(`/getDoctors/${dept}`);
    },

    getDeptDoct() {
        return API.get('/getDeptDoct');
    },
    postAppointmemnt(formData) {
        return API.post('/postAppointmemnt', { formData });
    },
    GetAppointments() {
        return API.get('/GetAppointments');
    },
    calllead() {
        return API.post('/calllead');
    },

    visitor() {
        return API.post('/visitor');
    },
    getVisitors() {
        return API.get('/getVisitors');
    },
    getServices() {
        return API.get('/getServices');
    },
    getServiceBySlug(slug) {
        return API.get(`/getServiceBySlug/${slug}`);
    },

    deleteService(id) {
        return API.post('/deleteService', { id });
    },
    getCallLeads(startDate, endDate) {
        return API.post('/getCallLeads', {startDate, endDate});
    },






    // login 
    register(formData) {
        return API.post('/register', { formData });
    },

    login(email, password) {
        return API.post('/login', { email, password })
    }




}