import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import api from "../../API/api";

import { useNavigate } from "react-router-dom";

const DashboardPage = () => {

    const navigation = useNavigate();
    const [count, setCount]= useState();


    useEffect(() => {
        getVisitors();

    }, []);

    const getVisitors = () => {
        api.getVisitors().then((res) => {
            console.log(res.data.count);
            setCount(res.data.count)
        })
    }




    return (
        <>
            <div className="containe">
                <div className="row">
                    <div className="col-4 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Appointments : {count && count.tot_appoint}</h5>
                            </div>
                        </Paper>
                    </div>
                    <div className="col-4 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Departments : {count && count.tot_depart }</h5>
                            </div>
                        </Paper>
                    </div>
                    <div className="col-4 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Doctors : {count && count.tot_doc}</h5>
                            </div>
                        </Paper>
                    </div>
                    <div className="col-6 p-2">
                        <Paper elevation={3} onClick={()=> navigation('/admin/CallLeads')}>
                            <div className="p-3">
                                <h5>Call Now Button Clicked : {count && count.tot_callUs}</h5>
                            </div>
                        </Paper>
                    </div>
                    <div className="col-6 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5>Total Visitors : {count && count.tot_visitors}</h5>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage;