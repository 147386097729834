import React, { useEffect, useState } from 'react'
import NavbarHHH from '../components/common/nav'
import Hero from '../components/home/hero'
import Footer from '../components/common/footer'
import { useParams } from 'react-router-dom'
import { Divider } from '@mui/material'
import { CallUs } from '../components/panel/callus'
import AppoinmentModal from '../components/modal/appointment'

import api from '../API/api'

const ServicePage = () => {

    const slug = useParams('slug');

    const [service, setService] = useState();

    useEffect(() => {
        console.log(slug);
        getServiceBySlug()
    }, [])

    const getServiceBySlug = () => {
        api.getServiceBySlug(slug.slug).then((res) => {
            console.log(res);
            setService(res.data.service)
        }).catch((err) => {
            console.log(err.response);
        })
    }


    const page = () => {
        return (
            <>
                <div>
                    <div className="container">
                        <div className='text-center m-3 py-4'>
                            {service &&
                                <>
                                    <div className="text-center">
                                    </div>
                                    <h2>
                                        {service.service} &nbsp;
                                        <img src={`${process.env.REACT_APP_BACKEND}assets/service/${service.icon}`} className="img-fluid" width={50} alt="img" />
                                    </h2>
                                    <Divider />
                                   
                                    <div className='m-3 py-3'>
                                        <div dangerouslySetInnerHTML={{ __html: service.description }} />
                                    </div>
                                    <div>
                                        <CallUs />
                                        <AppoinmentModal />
                                    </div>
                                </>
                            }


                        </div>
                    </div>
                </div>

            </>
        )

    }






    return (
        <>
            <NavbarHHH />
            <Hero banner={service && service.banner} />

            {page()}

            <Footer />


        </>
    )
}

export default ServicePage;