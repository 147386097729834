import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AppoinmentModal from '../modal/appointment';
import { Link } from 'react-router-dom';
import { CallUsWithoutText, CallUs } from '../panel/callus';

const NavbarHHH = () => {

    const [fixed, setFixed] = useState(false);

    function setFix() {
        if (window.scrollY >= 100) {
            setFixed(true)
        }
        else {
            setFixed(false);
        }
    }

    window.addEventListener("scroll", setFix)


    return (
        <>
            <div className='text-center p-2'>
                <div style={{ 'width': '100px', 'margin': '0 auto' }}>
                    <img src='https://apolloclinicguwahati.com/wp-content/uploads/2020/12/0001-e1629537186407-1024x546.jpg.webp' className='img-fluid' alt="logo" />
                </div>
            </div>

            {['sm'].map((expand) => (
                <Navbar key={expand} expand={expand} className={fixed ? 'fixed' : 'p-0'}>
                    <Container>

                        <CallUs />

                        {/* &nbsp; */}

                        <AppoinmentModal />

                        <Navbar.Brand href="#">
                            {/* <div style={{'width':'100px'}}>
                                {fixed &&
                                    <img src='https://www.apollohospitals.com/wp-content/themes/apollohospitals/assets-v3/images/logo.svg' className='img-fluid'  alt="logo" />
                                }
                            </div> */}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    APOLLO CLINIC
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center flex-grow-1 pe-3">
                                    <Link to="/" className='nav-link'>HOME</Link>
                                    <Link to="/#deptartment" className='nav-link' href="/#perks">DEPARTMENTS</Link>
                                    <Link className='nav-link' href="/#model">CONTACT US</Link>
                                </Nav>

                            </Offcanvas.Body>
                        </Navbar.Offcanvas>



                    </Container>
                </Navbar>
            ))}
        </>
    );
}
export default NavbarHHH;