import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Appointmemnt from '../form/appointment';
import { Card, Paper } from '@mui/material';
import { CallUs } from '../panel/callus';

export default function DoctorProfile(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const profile = () => {
        return (
            <>
                <Paper>
                    <div className='m-2 p-2'>
                        <div className="row">
                            <div className="col-md-4">
                                <div className='doctor-profile pl-3'>
                                    <img src={`${process.env.REACT_APP_BACKEND}assets/doctor/${props.doctor.image}`} className="col-12" alt="img" />
                                </div>
                            </div>

                            <div className="col-md-8 d-flex align-items-center my-2">
                                <div>
                                    <h4> {props.doctor.name} </h4>
                                    {props.doctor.qualification &&
                                        <div>
                                            <i> {props.doctor.qualification}</i>
                                        </div>
                                    }
                                    <div>
                                        <strong>Department:  {props.department.department}</strong>
                                    </div>

                                    {props.doctor.specialization &&
                                        <div className='text-small'>
                                            <strong>Specialization:  {props.doctor.specialization}</strong>
                                        </div>
                                    }

                                    {props.doctor.achievements && props.doctor.achievements !== null && props.doctor.achievements !== 'null' &&
                                        <div className='text-small'>
                                            <strong>Achievements:  {props.doctor.achievements}</strong>
                                        </div>
                                    }
                                    {props.doctor.description &&
                                        <div className='my-2'>
                                            {/* <span> {props.doctor.description}</span> */}
                                            <div dangerouslySetInnerHTML={{ __html: props.doctor.description }} />
                                        </div>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                    <Divider sx={{ borderBottomWidth: 5, color: 'black' }} />
                </Paper>
            </>
        )
    }



    return (
        <React.Fragment>
            <Button variant='contained' size='small' className='my-3' onClick={handleClickOpen}>
                View Profile
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Doctor Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* You can set my maximum width and whether to adapt or not. */}
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                    >
                        {profile()}

                        <Appointmemnt dept={props.department.department} doctor={props.doctor.name} handleClose={handleClose} />

                    </Box>
                </DialogContent>
                <DialogActions>
                    <CallUs />
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}