import React from 'react'
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import api from '../../API/api';

const calllead = () => {
    api.calllead()
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err.response);
        })


    window.location = `tel:${process.env.REACT_APP_PHONE_NO}`
}



export const CallUs = () => {
    return (
        <>
            <Button variant='contained' size='small' className='m-2' color='success' onClick={calllead}> <CallIcon /> Call Us</Button>
        </>
    )
}

export const CallUsWithoutText = () => {
    return (
        <>
            <Button variant='contained' size='small' sx={{ backgroundColor: 'white', color: 'green' }} style={{ maxWidth: '30px', minWidth: '30px' }} onClick={calllead}> <CallIcon /> </Button>
        </>
    )
}
