import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Appointmemnt from '../form/appointment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { CallUs } from '../panel/callus';


export default function AppoinmentModal(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <React.Fragment>
            <Button variant='contained' size='small' color='warning' className='blinking-button' onClick={handleClickOpen}>
                <EventNoteIcon /> &nbsp; Appointment
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Appointment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* You can set my maximum width and whether to adapt or not. */}
                    </DialogContentText>
                    

                        <Appointmemnt props={props} handleClose={handleClose} />


                </DialogContent>
                <DialogActions>
                    <CallUs />
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}