import React from 'react';

const AboutSection = () => {
    return (
        <section className='p-2 py-5 bg'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="text-center">
                            <h1>About Us</h1>
                            <p  style={{textAlign: 'justify'}}>Apollo Clinics are multi-specialty clinics run by Apollo Health & Lifestyle Limited (AHLL), a subsidiary of Apollo Hospitals Enterprise Limited (AHEL).
                                Apollo Clinic is an integrated model and offers facilities for Specialist Consultation, Diagnostics, Preventive Health Checks, Pharmacy, Free Home Sample Collections, Dentistry, Physiotherapy, Apollo Hospitals Information Center, Tele-medicine, Day Care, etc. all under one roof.<br />

                                Apollo Clinic is committed to not only providing consistently superior quality health care services but also addressing the day-to-day health care needs of the family.<br />

                                Our vision is to bring healthcare of International standards within the reach of every individual. We are committed to the achievement and maintenance of excellence in education, research and healthcare for the benefit of humanity.</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="image-1">
                            <img src='/apollo.jpg' alt='logo' className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default AboutSection;