import React from 'react'
import NavbarHHH from '../components/common/nav'
import Footer from '../components/common/footer'
import Doctors from '../components/home/doctor'
import { Helmet } from "react-helmet";

const DoctorPage = () => {
    return (
        <>

            <Helmet>
                <title>Apollo | Doctors</title>
                {/* <meta name="description" content="Admin Content" /> */}
            </Helmet>
            <NavbarHHH />
            <Doctors />
            <Footer />
        </>
    )
}

export default DoctorPage