import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import { Card, Paper } from '@mui/material';
import api from '../../API/api';
import { useNavigate, Link } from 'react-router-dom';

const htmlToFormattedText = require("html-to-formatted-text");

const Service = () => {


    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices();
    }, [])

    const getServices = () => {
        api.getServices().then((res) => {
            setServices(res.data.services)
        })
    }


    return (
        <>
            <section className='p-2 py-5 bg'>
                <div className="container">
                    <div className="row">
                        <div className='text-center'>
                            <h2>Our Services</h2>
                            <div className='line'></div>
                        </div>
                    </div>
                    <br />

                    <div>
                        <Swiper slidesPerView={1}
                            spaceBetween={30}
                            // loop={true}
                            modules={[Autoplay, Scrollbar, Pagination]}
                            //autoplay={false}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                // when window width is >= 640px
                                460: {
                                    //   width: 640,
                                    slidesPerView: 2,
                                },
                                // when window width is >= 768px
                                768: {
                                    //   width: 768,
                                    slidesPerView: 3,
                                },
                                968: {
                                    //   width: 768,
                                    slidesPerView: 4,
                                },

                            }}
                            speed={1500}
                            pagination={{ clickable: true }}
                        >


                            {services && services.map((el) => (


                                <SwiperSlide>
                                    <div className='row m-1'>
                                        <Paper elevation={4}>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <div className='d-flex justify-content-center align-items-center service-icon'>
                                                    <img src={`${process.env.REACT_APP_BACKEND}assets/service/${el.icon}`} className='' height={80} alt="img" />
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <Link to={`/service/${el.slug}`}> <strong className='text-small'> {el.service} </strong> </Link>
                                                <p>

                                                    {htmlToFormattedText(el.description).slice(0, 130)}...
                                                </p>
                                            </div>
                                        </Paper>
                                    </div>

                                </SwiperSlide>

                            )
                            )}

                        </Swiper>
                    </div>



                </div>
            </section>
        </>
    )
}

export default Service