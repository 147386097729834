import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Homepage from './PublicPages/homepage';

import Dashboard from './AdminPages/dashboard/dashboard';
import AddDepartmentPage from './AdminPages/pages/addDepartment';
import ViewDepartmentPage from './AdminPages/pages/viewDepartment';
import Doctors from './components/home/doctor';

import DoctorPage from './PublicPages/doctorPage';

import AddDoctorPage from './AdminPages/pages/addDoctor';
import ViewDoctor from './AdminPages/pages/viewDoctor';
import ViewAppointments from './AdminPages/pages/appointments';
import DashboardPage from './AdminPages/pages/Dashboard';
import AddServices from './AdminPages/pages/addServices';
import ServicePage from './PublicPages/servicePage';
import CallLeads from './AdminPages/pages/callLeads';


import Login from './AdminPages/form/login';


import api from './API/api';

function App() {

  useEffect(() => {
    visitor();
  }, []);

  const visitor = () => {
    api.visitor().then((res) => {
      console.log("welcome");
    })
  }

  const [user, setUser] = useState('');
  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      setUser('admin')
    } else {
      setUser('public')
    }

  }, [user]);




  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/department/:dept*" element={<Doctors />} /> */}
        <Route path="/department/:dept*" element={<DoctorPage />} />
        <Route path="/login" element={<Login />} />
        <Route path='/service/:slug' element={<ServicePage />} />

      </Routes>


      {/* <Routes>
        <Route path='/admin' element={<Dashboard />} />
      </Routes> */}

      {user === 'admin' &&
        <Routes>
          {/* admin  */}
          <Route path='/admin' element={<Dashboard />}>
            <Route path='dashboard' element={<DashboardPage />} />
            <Route path='addDepartment' element={<AddDepartmentPage />} />
            <Route path='viewDepartment' element={<ViewDepartmentPage />} />
            <Route path='addAdminDoctor' element={<AddDoctorPage />} />
            <Route path='viewDoctor' element={<ViewDoctor />} />
            <Route path='AddServices' element={<AddServices />} />
            <Route path='appointments' element={<ViewAppointments />} />
            <Route path='CallLeads' element={<CallLeads />} />

          </Route>

        </Routes>
      }

    </>
  );
}

export default App;
