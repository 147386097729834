import React from 'react'
import NavbarHHH from '../components/common/nav'
import Hero from '../components/home/hero'
import Footer from '../components/common/footer'
import AboutSection from '../components/home/about'
import Department from '../components/home/department'
import Service from '../components/home/service'

const Homepage = () => {


    return (
        <>
            <div>
                <NavbarHHH />
                <Hero />
                <AboutSection />
                <Department />
                <Service />
                <Footer />
            </div>
        </>
    )
}

export default Homepage;