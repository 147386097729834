import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import api from '../../API/api';
import { Card, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2'

const Appointmemnt = (props) => {


    const [doctors, setDoctors] = useState();
    const [dept, setDept] = useState();

    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        age: '',
        phone: '',
        city: '',
        date: '',
        dept: '',
        doctor: '',

    })

    useEffect(() => {
        console.log("props", props);
        getDoctors();

        if (props) {
            getDoctorById(props.dept)
            setFormData({
                ...formData,
                'dept': props.dept,
                'doctor': props.doctor
            })
        }
    }, [])

    const handleInpute = (e) => {
        console.log(e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const getDoctors = () => {
        api.getDeptDoct()
            .then((res) => {
                console.log(res);
                setDept(res.data.dept)
                // setDoctors(res.data.doctors)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const changeDept = (e) => {
        console.log(e.target.value);
        getDoctorById(e.target.value);

        setFormData({
            ...formData,
            'dept': e.target.value,
            'doctor': '',
        })
    }

    const getDoctorById = (data) => {
        api.getDoctors(data)
            .then((res) => {
                console.log("d", res);
                setDoctors(res.data.doctor)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleSubmit = () => {

        if(formData.name === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Name Can not be empty !',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            props.handleClose()
            return ;
        }
        if(formData.phone === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Phone Number Can not be empty !',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            props.handleClose()
            return ;
        }
        if(formData.date === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Date Can not be empty !',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            props.handleClose()
            return ;
        }
        if(formData.dept === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Department Can not be empty !',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            props.handleClose()
            return ;
        }
        if(formData.doctor === ''){
            Swal.fire({
                title: 'Error!',
                text: 'Doctor Can not be empty !',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            props.handleClose()
            return ;
        }


        api.postAppointmemnt(formData)
            .then((res) => {
                console.log(res);
                Swal.fire({
                    title: 'Success!',
                    text: 'Your Request Submmited Succeffully !',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })

                props.handleClose()
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <>
            <Paper>
                <div className='m-2'>
                    <div className="row">

                        <div className="col-md-6 mt-4">
                            <label htmlFor="">Your Name</label>
                            <input type="text" className='form-control' onChange={handleInpute} value={formData.name} name="name" id="" />
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Gender</label>

                            <select className='form-control' onChange={handleInpute} value={formData.gender} name="gender" id="">
                                <option value="">-select-</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>


                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Age</label>
                            <input type="number" className='form-control' onChange={handleInpute} value={formData.age} name="age" id="" />
                        </div>

                        <div className="col-md-6 mt-4">
                            <label htmlFor="">Contact Number</label>
                            <input type="number" className='form-control' onChange={handleInpute} value={formData.phone} name="phone" id="" />
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">City</label>
                            <input type="text" className='form-control' onChange={handleInpute} value={formData.city} name="city" id="" />
                        </div>
                        <div className="col-md-3 mt-4">
                            <label htmlFor="">Appointment Date</label>
                            <input type="date" className='form-control' onChange={handleInpute} value={formData.date} name="date" id="" />
                        </div>

                        <div className="col-md-6 mt-4">
                            <label htmlFor="">Department</label>
                            <select name="dept" className='form-control' id="" value={formData.dept} onChange={changeDept}>
                                <option value="">-select--</option>
                                {dept && dept.map((dep) =>
                                    <option value={dep.department}>{dep.department}</option>
                                )}
                            </select>
                        </div>

                        <div className="col-md-6 mt-4">
                            <label htmlFor="">Doctor Name</label>
                            <select name="doctor" className='form-control' onChange={handleInpute} id="" value={formData.doctor} disabled={dept ? null : true} >
                                <option value="">-select--</option>
                                {doctors && doctors.map((doc) =>
                                    <option value={doc.name}>{doc.name}</option>
                                )}
                            </select>

                        </div>

                        <div className="text-center my-4">
                            <div className='btn btn-danger btn-sm' onClick={handleSubmit}>Request Appointment <SendIcon /> </div>
                        </div>


                    </div>
                </div>
            </Paper>
        </>
    )
}

export default Appointmemnt