import React, { useState, useEffect } from 'react';

import api from '../../API/api';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AddDoctorModal from '../panel/modalDoctor';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddDoctorPage from '../pages/addDoctor';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ViewDoctor = () => {

    const [doctor, setDoctor] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState();

    useEffect(() => {
        viewDoctors();
    }, [])

    const viewDoctors = () => {
        api.getDeptDoct()
            .then((res) => {
                console.log(res);
                setDoctor(res.data.doctors)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (data) => {
        setOpen(true);
        setSelectedDoctor(data);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const updateDoctor = (data) => {
        return (
            <>
                <React.Fragment>
                    <div className='m-2'>

                    </div>

                </React.Fragment>
            </>
        )
    }

    return (
        <>
            <AddDoctorModal />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Specialization</StyledTableCell>
                            <StyledTableCell align="center">Department</StyledTableCell>
                            <StyledTableCell align="center">Qualification</StyledTableCell>
                            <StyledTableCell align="center">Achievements</StyledTableCell>
                            <StyledTableCell align="center">Image</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {doctor && doctor.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">{row.specialization}</StyledTableCell>
                                <StyledTableCell align="center">{row.department}</StyledTableCell>
                                <StyledTableCell align="center">{row.qualification}</StyledTableCell>
                                <StyledTableCell align="center">{row.achievements}</StyledTableCell>
                                <StyledTableCell align="center">{row.image}</StyledTableCell>
                                <StyledTableCell align="center"><Button variant='contained' size='small' color='warning' onClick={() => handleClickOpen(row)}>
                                    + Edit
                                </Button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Doctor</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                    >

                        <AddDoctorPage data={selectedDoctor} handleClose={handleClose}/>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewDoctor;