import React, { useEffect, useState } from 'react';

import NavbarHHH from '../common/nav';
import Footer from '../common/footer';

import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import Hero from './hero';
import DoctorProfile from '../modal/profile';
import { Card, Paper, Button                  } from '@mui/material';
import AppoinmentModal from '../modal/appointment';
import Service from './service';
import { CallUs } from '../panel/callus';

import api from '../../API/api';



const Doctors = () => {

    const [doctor, setDoctor] = useState();
    const [department, setDepartment] = useState();

    let { dept } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("dept", dept);
        getDoctors();
    }, [])

    const getDoctors = () => {
        api.getDoctors(dept)
            .then((res) => {
                console.log(res);
                setDoctor(res.data.doctor)
                setDepartment(res.data.dept);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <>
            {/* <NavbarHHH /> */}
            <Hero banner={department && department.banner} />

            <div className="container">
                <div className='text-center m-3 py-4'>
                    {department &&
                        <>
                            <div className="text-center">
                            </div>
                            <h2>
                                Department of {department.department} &nbsp;
                                <img src={`${process.env.REACT_APP_BACKEND}assets/department/${department.cover}`} className="img-fluid" width={50} alt="img" />
                            </h2>
                            <span>{department.description}</span>
                        </>
                    }


                </div>
            </div>

            <div className='text-center m-4 pt-4'>
                       <CallUs />
                    </div>
            <Divider variant="middle" sx={{ borderBottomWidth: 5 }} />


            <section className='p-1'>

                <div className='text-center m-2'>
                    <h3> Our Consultant Panel </h3>
                    <div className='line'></div>
                </div>
                <br />


                <div className="container">
                    <div className="row justify-content-center">
                        {doctor && doctor.map((view) => (
                            <div className="col-md-3 mt-2">
                                <Paper elevation={7}>
                                    <div className="p-3">
                                        <div className="image text-center">
                                            <img src={`${process.env.REACT_APP_BACKEND}assets/doctor/${view.image}`} className="img-fluid" alt="img" />
                                        </div>
                                        <br />
                                        <Divider />
                                        <div className="card-body text-center mt-3">

                                            <div className='about'>
                                                <h6 className="card-title text-center">
                                                    <strong>{view.name}
                                                    </strong>
                                                </h6>
                                                {/* <p><strong></strong>{view.specialization}</p> */}
                                                <p><strong></strong>{view.qualification}</p>
                                            </div>

                                            <div className="text-center">
                                                <DoctorProfile doctor={view} department={department} />

                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ))}
                    
                    </div>

                    <div className='text-center m-4 pt-4'>
                       {/* <CallUs /> */}
                        <AppoinmentModal />
                    </div>


                </div>
            </section>

            <Service />

            {/* <Footer /> */}
        </>
    )
}

export default Doctors;