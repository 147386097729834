import React, { useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import axios from 'axios';

const AddDepartmentPage = () => {

    const [coverpreview, setCoverpreview] = useState();
    const [bannerpreview, setBannerpreview] = useState();
    const [cover, setCover] = useState();
    const [banner, setBanner] = useState();

    const [name, setName] = useState({
        department: '',
        cover: '',
        description: '',
    })

    const handleInput = (e) => {
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }

    const handleCoverImg = (e) => {
        setCover(e.target.files[0]);
        setCoverpreview(URL.createObjectURL(e.target.files[0]))
    }
    const handleBannerImg = (e) => {
        setBanner(e.target.files[0]);
        setBannerpreview(URL.createObjectURL(e.target.files[0]))
    }

    const handleSubmit = () => {
        console.log("=>>", name);

        const postData = new FormData();
        postData.append('department', name.department);
        postData.append('description', name.description);
        postData.append('cover', cover);
        postData.append('banner', banner);

        axios.post(`${process.env.REACT_APP_MAIN_API}postDepartments`, postData)
            .then((res) => {
                console.log(res);
                Swal.fire({
                    title: 'Success!',
                    text: 'Added Succeffully !',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    return (
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="p-5 border">
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <label htmlFor="">Department name:</label>
                                <TextField id="standard-basic" label="Add Department" variant="outlined" size="small" className="form-control" name='department' onChange={handleInput} value={name.department} />

                                <label htmlFor="">Department Description:</label>
                                <textarea className='form-control' id="" rows="3" name='description' onChange={handleInput}>{name.description}</textarea>

                                <br />


                                <div className="col-md-12 form-group my-3">
                                    <label htmlFor="">Department Icon/Image</label>
                                    <input type="file" className="form-control" name="cover" id="imgInp" onChange={handleCoverImg} />
                                </div>
                                {coverpreview &&
                                    <div className="col-md-12 form-group">
                                        <label>Preview</label> <br />
                                        <div className="border text-center p-3" style={{ height: "auto", alignItems: "center" }}>
                                            <img id="blah" src={coverpreview} alt="Cover Image Preview" style={{ height: "180px", width: "fit-content" }} />
                                        </div>
                                    </div>
                                }

                                <div className="col-md-12 form-group my-3">
                                    <label htmlFor="">Department Banner</label>
                                    <input type="file" className="form-control" name="banner" id="imgInp" onChange={handleBannerImg} />
                                </div>
                                {bannerpreview &&
                                    <div className="col-md-12 form-group">
                                        <label>Preview</label> <br />
                                        <div className="border text-center p-3" style={{ height: "auto", alignItems: "center" }}>
                                            <img id="blah" src={bannerpreview} alt="Cover Image Preview" style={{ height: "180px", width: "fit-content" }} />
                                        </div>
                                    </div>
                                }
                                <div className="text-center my-4">
                                    <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                                </div>

                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default AddDepartmentPage;