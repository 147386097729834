import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddDoctorPage from '../pages/addDoctor';




const AddDoctorModal = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    return (
        <>
            <React.Fragment>
                <div className='m-2'>
                    <Button variant='contained' size='small' color='warning' className='blinking-button' onClick={handleClickOpen}>
                        + Add Doctor
                    </Button>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Doctor</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        <Box
                            noValidate
                            component="form"
                        >

                            <AddDoctorPage handleClose={handleClose} />


                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        </>
    )





}


export default AddDoctorModal;