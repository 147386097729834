import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material'
import AppoinmentModal from '../modal/appointment';
import { Button } from '@mui/material';
import api from '../../API/api';
import { useNavigate } from "react-router-dom";
import { CallUs } from '../panel/callus';

const Department = () => {

    const [department, setDepartment] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        viewDepartment()
    }, [])

    const viewDepartment = () => {
        api.getDepartments()
            .then((res) => {
                console.log(res);
                setDepartment(res.data.department)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <section className='py-5' id='deptartment'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="text-center mb-5">
                            <h1>Our Departments</h1>
                            <div className='line'></div>
                        </div>
                    </div>

                    {department && department.map((view) => (
                        <div className="col-6 col-md-2 mb-3">
                            <Card>
                                <div className=" card-box p-3" onClick={() => navigate(`/department/${view.department}`)}>
                                    <div className="text-center">
                                        <img src={`${process.env.REACT_APP_BACKEND}assets/department/${view.cover}`} className="img-fluid col-8" alt="img" />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title text-center mt-2">{view.department}</h6>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))}

                </div>

                <div className='text-center m-4 pt-4'>
                    <CallUs />

                    <AppoinmentModal />
                </div>
            </div>
        </section>
    )
}

export default Department;