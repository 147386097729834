import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

const Hero = (props) => {
    return (

        <>
            <Swiper slidesPerView={1}
                loop={true}
                modules={[Autoplay, Scrollbar, Pagination]}
                //autoplay={false}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                speed={1500}
                pagination={{ clickable: true }}
            >

{props.banner ? 
                <SwiperSlide>
                    <div className="hero">
                        <div className="card bg-dark text-white border-0">
                            <img src={`${process.env.REACT_APP_BACKEND}assets/department/banner/${props.banner}`} className="card-img" alt="Banner" />
                            <div className="card-img-overlay d-flex flex-column justify-content-center">
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                :
                <SwiperSlide>
                    <div className="hero">
                        <div className="card bg-dark text-white border-0">
                            <img src="/1920x500_1.jpg" className="card-img" alt="Banner" />
                            <div className="card-img-overlay d-flex flex-column justify-content-center">
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


}



            </Swiper>
        </>
    );
}

export default Hero;