import React from "react";

const Footer = () => {
    return (
        <>
            <footer className="footer text-center text-lg-start text-white bg-dark">
                <section>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    APOLLO Clinic
                                </h6>
                                <p>Apollo Clinics are multi-specialty clinics run by Apollo Health & Lifestyle Limited (AHLL), a subsidiary of Apollo Hospitals Enterprise Limited (AHEL).
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Contact Us
                                </h6>
                                <p>
                                    Apollo Clinic<br />
                                    Opp Downtown Hospital
                                    Dispur, Guwahati, Assam<br />
                                    Phone No: 7664812001
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Clinic Timing
                                </h6>
                                <p>
                                    Monday - Sunday<br />
                                    7:30 AM – 9:00 PM
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center p-4 bg-secondary">
                    © 2023 Copyright Apollo Clinic
                </div>
            </footer>
        </>
    );
};

export default Footer;