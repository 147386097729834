import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Swal from 'sweetalert2'
import axios from 'axios';
import api from '../../API/api';
import JoditEditor from 'jodit-react';
import { Paper } from '@mui/material';

const AddDoctorPage = (props) => {

    const [coverpreview, setCoverpreview] = useState();
    const [cover, setCover] = useState();

    // for all department getting from api 
    const [department, setDepartment] = useState();

    const [doctorName, setDoctorName] = useState({
        id: '',
        name: '',
        specialization: '',
        department: '',
        qualification: '',
        achievements: '',
        image: '',
    })

    const [description, setDescription] = useState('');

    useEffect(() => {
        if (props.data) {
            console.log(props.data);
            setDoctorName(props.data)
            setDescription(props.data.description)
        }

        viewDepartment()
    }, [])

    const viewDepartment = () => {
        api.getDepartments()
            .then((res) => {
                console.log("===>>>>", res);
                setDepartment(res.data.department)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleInput = (e) => {
        setDoctorName({
            ...doctorName,
            [e.target.name]: e.target.value
        })
    }

    const handleCoverImg = (e) => {
        setCover(e.target.files[0]);
        setCoverpreview(URL.createObjectURL(e.target.files[0]))
    }

    const handleSubmit = () => {
        console.log("=>>", doctorName);
        console.log("=>>", description);
        // return;
        const postData = new FormData();
        postData.append('id', doctorName.id);
        postData.append('name', doctorName.name);
        postData.append('specialization', doctorName.specialization);
        postData.append('department', doctorName.department);
        postData.append('qualification', doctorName.qualification);
        postData.append('achievements', doctorName.achievements);
        postData.append('description', description);
        postData.append('image', cover);

        axios.post(`${process.env.REACT_APP_MAIN_API}postDoctors`, postData)
            .then((res) => {
                console.log(res);
                props.handleClose();

                Swal.fire({
                    title: 'Success!',
                    text: 'Added Succeffully !',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleDetails = (e) => {
        setDescription(e)
    }


    // jodit editor
    const editor = useRef(null);

    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 420,
        triggerChangeEvent: true,
        readonly: false,
    }

    const Jodit = () => {
        return useMemo(() => (
            <JoditEditor
                ref={editor}
                config={config}
                value={description}
                onChange={handleDetails}
            />
        ), [department])
    }




    return (
        <section>
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <Paper elevation={5}>
                            <div className="p-5 border">
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1 },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField id="standard-basic" label="Doctor's Name" variant="outlined" size="small" className="form-control" name='name' onChange={handleInput} value={doctorName.name} />

                                    <TextField id="standard-basic" label="Specialization" variant="outlined" size="small" className="form-control" name='specialization' onChange={handleInput} value={doctorName.specialization} />

                                    <FormControl fullWidth size="small" variant='outlined'>
                                        <InputLabel id="demo-select-small-label">Select Department </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={doctorName.department}
                                            label="Select Department"
                                            onChange={handleInput}
                                            name='department'
                                        >
                                            {department && department.map((dept) => (
                                                <MenuItem value={dept.id}>{dept.department}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>

                                    <TextField id="standard-basic" label="Qualification" variant="outlined" size="small" className="form-control" name='qualification' onChange={handleInput} value={doctorName.qualification} />

                                    <TextField id="standard-basic" label="Achievement" variant="outlined" size="small" className="form-control" name='achievements' onChange={handleInput} value={doctorName.achievements} />

                                    <label htmlFor="">Description</label>
                                    {/* <textarea name="description" id="" cols="30" className='form-control' rows="3" onChange={handleInput} >{doctorName.description}</textarea> */}

                                    {Jodit()}

                                    <div className="col-md-12 form-group my-3">
                                        <input type="file" className="form-control" name="cover" id="imgInp" onChange={handleCoverImg} />
                                    </div>
                                    {coverpreview &&
                                        <div className="col-md-12 form-group">
                                            <label>Preview</label> <br />
                                            <div className="border text-center p-3" style={{ height: "auto", alignItems: "center" }}>
                                                <img id="blah" src={coverpreview} alt="Cover Image Preview" style={{ height: "180px", width: "fit-content" }} />
                                            </div>
                                        </div>
                                    }

                                    <div className="text-center my-4">
                                        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                                    </div>

                                </Box>

                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default AddDoctorPage;