import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddDoctorPage from '../pages/addDoctor';

import JoditEditor from 'jodit-react';
import Swal from 'sweetalert2'
import axios from 'axios';
import api from '../../API/api';



const AddServiceModal = (props) => {

    useEffect(() => {
        setFormData(props.data)
        setDescription(props.data.description)
    }, []);


    const [formData, setFormData] = useState({
        id: '',
        service: '',
        shortDetails: ''
    })
    const [icon, setIcon] = useState();
    const [banner, setBanner] = useState();
    const [description, setDescription] = useState();
    const [clicked, setClicked] = useState(false)

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const handleIcon = (e) => {
        setIcon(e.target.files[0]);
    }
    const handleBanner = (e) => {
        setBanner(e.target.files[0]);
    }


    // jodit editor

    const handleDetails = (e) => {
        setDescription(e);
    }

    const editor = useRef(null);

    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 420,
        triggerChangeEvent: true,
        readonly: false,
    }

    const Jodit = () => {
        return useMemo(() => (
            <JoditEditor
                ref={editor}
                config={config}
                value={formData.description}
                onChange={handleDetails}
            />
        ), [formData])
    }


    const handleSubmit = () => {

        setClicked(true);

        const postData = new FormData();
        postData.append('id', formData.id)
        postData.append('service', formData.service);
        postData.append('icon', icon);
        postData.append('description', description);
        postData.append('shortDetails', formData.shortDetails);
        postData.append('banner', banner);

        axios.post(`${process.env.REACT_APP_MAIN_API}postServices`, postData)
            .then((res) => {
                console.log(res);
                setClicked(false);
                props.handleClose();
                props.getServices();

                Swal.fire({
                    title: 'Success!',
                    text: 'Added Succeffully !',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            })
            .catch((err) => {
                console.log(err.response);
            })
    }



    const AddService = () => {
        return (
            <>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <label htmlFor="">Service Name</label>
                                <input type="text" className='form-control' name='service' onChange={handleInput} value={formData.service} />
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="">Service Icon</label>
                                <input type="file" className='form-control' name='icon' onChange={handleIcon} />
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="">Service Banner</label>
                                <input type="file" className='form-control' name='banner' onChange={handleBanner} />
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="">Short Details</label>
                                <textarea name="shortDetails" id="" cols="30" rows="3" className='form-control' onChange={handleInput} value={formData.shortDetails} > </textarea>
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="">Description</label>
                                {Jodit()}
                            </div>

                            <div className="col-12 mt-3 text-center">
                                <Button variant='contained' onClick={handleSubmit} disabled={clicked ? 'disabled' : ''}> Save Service</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }



    return (
        <>
            {AddService()}

        </>
    )







}


export default AddServiceModal;